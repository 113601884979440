// my  services style st

.app__about-container {
  display: flex;
  flex-direction: column;

  .app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
  }

  .app__profile-item {
    // height: 260px;
    width: 300px;
    display: flex;
    // flex: 1;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    align-self: normal;
    flex-wrap: wrap;
    padding: 2rem 1rem;
    margin: 1rem;
    background-color: var(--color-secondary);

    span {
      color: var(--color-primary-yellow);
      margin-top: 1rem;
      font-size: 1rem;
    }
    .p-text {
      font-size: 0.8rem;
      text-align: left;
    }
    &:hover {
      cursor: pointer;
      img {
        filter: grayscale(1);
      }

      .p-text {
        span {
          color: var(--color-primary-yellow);
        }
      }
      box-shadow: 0px 0px 5px rgba(206, 159, 23, 0.5);
    }
    img {
      width: 60px;
    }

    @media screen and (min-width: 2000px) {
      // margin: 2rem 4rem;
      img {
        width: 320px;
      }
    }
  }
}

.app__testimonial-container {
  display: flex;
  flex-direction: column;

  .app__testimonial-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // background-color: var(--color-secondary);
    padding: 2rem 1rem;
    transition: all 0.3s ease-in-out;

    @media screen and(min-width:2000px) {
      min-height: 450px;
      img {
        width: 150px;
        height: 150px;
      }
    }

    @media screen and(max-width:800px) {
      width: 100%;
    }
    @media screen and(max-width:600) {
      flex-direction: column;
    }

    .app__testimonial-content {
      display: flex;
      flex-direction: row;
      width: 100%;
      .app__testimonial-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 10%;
        @media screen and(max-width:900px) {
          padding: 0 5%;
        }
        @media screen and(max-width:450px) {
          padding: 0;
        }
        @media screen and(min-width:2000px) {
          padding: 0 20%;
        }
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
        }
        margin: 1rem;
        .p-text {
          margin-top: 0.5rem;
          text-align: center;
        }
        h4 {
          font-weight: 600;
          color: var(--color-primary-yellow);
          margin-top: 2rem;
          margin-bottom: -1rem;
        }
        h5 {
          font-weight: 400;
          color: var(--color-light-blue);
        }
      }
    }
  }

  .app__testimonial-btns {
    display: flex;
    align-items: flex-start;
    div {
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      color: var(--color-light-blue);
      font-size: 1.5rem;
      @media screen and(max-width:900px) {
        font-size: 1rem;
      }
      @media screen and(min-width:2000px) {
        font-size: 2.5rem;
      }

      &:hover {
        color: var(--color-primary-yellow);
      }
    }
  }

  // brands style started
  .app__testimonials-brands {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;

    div {
      margin: 1rem;
      width: 100px;
      height: 100px;
      img {
        // border: 1rem solid var(--color-primary-yellow);
        // border-radius: 50%;
        width: 100%;
        object-fit: cover;
        filter: grayscale(1);
      }
      &:hover {
        img {
          filter: grayscale(0);
        }
        cursor: pointer;
      }

      @media screen and(min-width:2000px) {
        width: 210px;
        margin: 2rem;
      }

      @media screen and(max-width:450px) {
        width: 140px;
        margin: 1rem;
      }
    }

    @media screen and(max-width:800px) {
      width: 100%;
    }
  }
}

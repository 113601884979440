.app__footer {
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.app__contact-container {
  display: flex;
  flex-direction: column;
  .app__footer-cards {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap-reverse;
    margin: 4rem 2rem 2rem;
    @media screen and(max-width:768px) {
      width: 100%;
    }
    .app_footer-card {
      img {
        font-size: 0.3rem;
      }
      p {
        font-weight: 600;
      }
      a {
        font-weight: 500;
      }
      &:hover {
        box-shadow: 0px 0px 5px rgba(206, 159, 23, 0.5);
      }

      @media screen and(max-width:450px) {
        width: 100%;
      }
    }
  }

  .app__footer-cards .app_footer-card:last-child {
    background-color: var(--color-primary-yellow);
  }

  .app__footer-form {
    width: 80%;
    flex-direction: column;
    margin: 1rem 2rem;

    @media screen and(max-width:900px) {
      width: 100%;
    }
    div {
      width: 100%;

      margin: 0.75rem;
      border-radius: 10px;
      cursor: pointer;
      background-color: var(--color-secondary);

      transition: all 0.3s ease-in-out;

      input,
      textarea {
        width: 100%;
        padding: 0.95rem;
        border: none;
        border-radius: 7px;
        background-color: var(--color-secondary);
        font-family: var(--Poppins);
        color: var(--color-light-blue);
        outline: none;
      }

      textarea {
        height: 170px;
      }
      &:hover {
        box-shadow: 0px 0px 5px rgba(206, 159, 23, 0.5);
      }
    }

    button {
      padding: 1rem 2rem;
      border-radius: 10px;
      border: none;
      background-color: var(--color-secondary);
      font-weight: 500;
      color: var(--color-primary-yellow);
      outline: none;
      margin: 2rem 0 0 0;
      font-family: var(--Poppins);
      cursor: pointer;
      &:hover {
        box-shadow: 0px 0px 5px rgba(206, 159, 23, 0.5);
      }
    }
  }
}

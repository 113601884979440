/* google fonts link */

@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,400;0,500;1,700&family=Kdam+Thmor+Pro&family=Poppins:wght@200;400;600&display=swap");

/* defualt style for all website element */

* {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none;
}

/* public variable style that's used any where */

:root {
  /* public color portion started here */
  --color-primary-black: #20202a;
  --color-secondary: #24242f;
  --color-primary-yellow: #ce9f17;
  --color-light-blue: #e3e5fc;

  /* public width responsive control started here */
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;

  /* fonts fimaly style */
  --fira-sans: "Fira Sans";
  --sans-serif: "sans-serif";
  --Kdam-Thmor-Pro: "Kdam Thmor Pro";
  --Poppins: "Poppins";

  // shadows
  --boxShadow: 0px 19px 60px rgb(0 0 0 /8%);
  --cardBoxShadow: 0px 10px 20px rgb(0 0 0 /10%);
  --redCard: rgba(158, 91, 189, 0.08);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

/* public style ended! */

/* html scroll behavior started */

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--color-primary-black);
}

::-webkit-scrollbar {
  display: none;
}

/* font style */

h1 {
  font-family: var(--Kdam-Thmor-Pro);
}

h2,
h3,
h4,
h5,
h6 {
  font-family: var(--Poppins);
}

/* scrollbar hidden ended! */

/* headings style */

h1,
h2,
h3,
h4,
h5 {
  font-size: 500;
}

/* h1 size */

h1 {
  font-size: 2.5rem;
}

/* h1 and h5 style */

a {
  color: var(--color-light-blue);
  transition: var(--transation);
  text-decoration: none;
}

a:hover {
  color: var(--color-primary-yellow);
}

// navigation profile started

.my-navbar {
  position: relative;
  .app__profiles-info {
    background-color: var(--color-secondary);
    // height: 200px;
    width: 240px;
    margin: 5px;
    .app__profile-item-img {
      margin: 0 auto;
      position: relative;

      .about-me {
        width: 100%;
        aspect-ratio: 1/1;
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .about-me-img-bg {
        position: relative;
        width: 150px;
        height: 150px;
        background: var(--color-primary-black);
        border-radius: 50%;
        margin-left: -20px;
        overflow: hidden;
        &::before {
          content: "";
          position: absolute;
          inset: -1px 30px;
          background: linear-gradient(315deg, #ce9f17, #cccac3);
          transition: 0.5s;
          animation: animat 4s linear infinite;
        }

        &:hover::before {
          inset: -1px 0px;
        }

        @keyframes animat {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        &::after {
          content: "";
          position: absolute;
          background: var(--color-primary-black);
          border-radius: 50%;
          inset: 1px;
          z-index: 1;
        }

        &:hover {
          .about-me-img {
            img {
              opacity: 20%;
              transition: all 0.5s ease;
            }

            h2 {
              display: inline;
              transition: all 0.5s ease;
              font-size: 0.7rem;
              font-weight: 600;
            }

            h5 {
              display: inline;
              transition: all 0.5s ease;
            }

            a {
              display: inline;
              transition: all 0.5s ease;
            }
          }
        }

        // image styling

        .about-me-img {
          position: absolute;
          inset: 10px;
          border: 1px solid var(--color-primary);
          z-index: 3;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--color-primary-black);
          flex-direction: column;

          &:hover {
            border: 1px solid #24242f;
            background: var(--color-primary-black);
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.5s;
            pointer-events: none;
          }
          h2 {
            position: relative;
            color: var(--color-primary-yellow);
            text-align: center;
            font-weight: 800;
            text-transform: uppercase;
            letter-spacing: 0.2rem;
            margin-top: 50px;
            display: none;
          }

          h5 {
            position: relative;
            color: var(--color-light-blue);
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            font-size: 0.4rem;
            margin-top: -3px;
            display: none;
          }

          a {
            position: relative;
            text-decoration: none;
            color: var(--color-primary-yellow);
            background: var(--color-primary-black);
            padding: 0 5px;
            border-radius: 25px;
            font-weight: 200;
            font-size: 0.6rem;
            font-family: var(--Poppins);
            display: none;

            span {
              color: #ce9f17;
              font-size: 0.9rem;
              padding-top: 5px;
            }
          }
        }
      }
    }

    // bg-me-end
  }
}

// navigation menu started
.my-navbar {
  position: fixed;
  top: 20px;
  left: 10px;
  bottom: 20px;
  background: var(--color-primary-black);
  color: var(--color-light-blue);
  border-radius: 3px;
  width: 250px;
  box-sizing: initial;
  transition: width 0.5s;
  box-shadow: 0px 0px 10px rgba(206, 159, 23, 0.5);
  transition: var(--transation);
  /* width: max-content; */
  display: block;
  z-index: 2;
}

.my-navbar ul li b:nth-child(1) {
  position: absolute;
  height: 20px;
  top: -20px;
  width: 100%;
  background: var(--color-primary-yellow);
  display: none;
}

.my-navbar ul li b:nth-child(1)::before {
  content: "";
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  border-bottom-right-radius: 20px;
  background: var(--color-primary-black);
}

.my-navbar ul li b:nth-child(2) {
  position: absolute;
  height: 20px;
  bottom: -20px;
  width: 100%;
  background: var(--color-primary-yellow);
  display: none;
}

.my-navbar ul li b:nth-child(2)::before {
  content: "";
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  border-top-right-radius: 20px;
  background: var(--color-primary-black);
}

.my-navbar ul li.active b:nth-child(1),
.my-navbar ul li.active b:nth-child(2) {
  display: block;
}

.my-navbar ul {
  position: absolute;
  margin-top: 230px;
  width: 100%;
  left: 0;
  top: 0;
  display: block;
  padding-left: 5px;
  display: flex;
  gap: 0.2rem;
  flex-direction: column;
}

.my-navbar ul li {
  position: relative;
  display: block;
  display: flex;
  width: 100%;
}

.my-navbar ul li a {
  color: var(--color-light-blue);
  position: relative;
  display: block;
  display: flex;
  width: 100%;
  z-index: 1;
}

.my-navbar ul li.active {
  background: var(--color-primary-yellow);
  // box-shadow: 0px 0px 2px rgba(206, 159, 23, 0.5);
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  height: 30px;
  margin-bottom: -3px;
  // transition: var(--transation);
}

.my-navbar ul li.active a {
  color: var(--color-primary-black);
}

.my-navbar ul li a .my-nav-list-icon {
  position: relative;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  margin-left: 20px;
  margin-top: 6px;
}

.my-navbar ul li a .my-nav-list-icon span {
  padding-left: 6px;
  margin-top: -2px;
}

//nav bar information section started
.app__navbar-location {
  position: absolute;
  bottom: 0;
  margin-bottom: 90px;
  margin-left: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    font-size: 1rem;
    color: var(--color-light-blue);
    font-family: var(--Poppins);
    font-weight: 400;
  }

  span {
    font-weight: 400;
    font-family: var(--Poppins);
    font-size: 1rem;
    color: var(--color-primary-yellow);
    margin-top: 5px;
  }
}

// my-navigation bar contact started

.my-nav-contact {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  background-color: var(--color-secondary);
  height: 80px;
  position: absolute;
  bottom: 0;
  width: 240px;
  margin: 5px;
  // margin-top: 100px;
  padding: 15px;
}

.my-nav-contact .nav-contact-title {
  font-size: 1rem;
  text-align: center;
  font-family: var(--Poppins);
  font-weight: 400;
  color: #666666;
}

.my-nav-contact .nav-contact-icons {
  font-size: 1rem;
  text-align: center;
}
.my-nav-contact .nav-contact-icons a {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

@media screen and(max-width:900px) {
  .my-navbar {
    display: none;
  }
}

$color-html: hsl(45°, 80%, 45%);
$porcent-html: 95;
$porcent-css: 85;
$porcent-scss: 70;
$porcent-js: 80;
$porcent-ts: 55;
$porcent-nodejs: 30;
$porcent-react: 12;

@function strokeDashoffset($num) {
  @return 440 - (440 * $num) / 100;
}

* {
  box-sizing: border-box;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  //   background-color: $color-black;
}

.container__progressbars {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100%;
}

.progressbar {
  position: relative;
  width: 170px;
  height: 170px;
  margin: 1em;
  transform: rotate(-90deg);
}

.progressbar__svg {
  position: relative;
  width: 100%;
  height: 100%;
}

.progressbar__svg-circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke-width: 1;
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
  stroke: hsl(45°, 80%, 45%);
  stroke-linecap: round;
  transform: translate(5px, 5px); // stroke-width / 2
}

.circle {
  &-html {
    animation: anim_circle-html 1s ease-in-out forwards;
  }
  &-css {
    animation: anim_circle-css 1s ease-in-out forwards;
  }
  &-scss {
    animation: anim_circle-scss 1s ease-in-out forwards;
  }
  &-js {
    animation: anim_circle-js 1s ease-in-out forwards;
  }
  &-ts {
    animation: anim_circle-ts 1s ease-in-out forwards;
  }
  &-node {
    animation: anim_circle-node 1s ease-in-out forwards;
  }
  &-react {
    animation: anim_circle-react 1s ease-in-out forwards;
  }
  &-angular {
    animation: anim_circle-angular 1s ease-in-out forwards;
  }
  &-vue {
    animation: anim_circle-vue 1s ease-in-out forwards;
  }
}

// filter: drop-shadow(0 0 5px $color-html);

.progressbar__text {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  padding: 0.25em 0.5em;
  color: hsl(0, 0%, 100%);
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 0.25em;
  transform: translate(-50%, -50%) rotate(90deg);
  img {
    width: 50px;
    margin-bottom: 0.5rem;
  }
}

//  @keyframes

@keyframes anim_circle-html {
  to {
    stroke-dashoffset: strokeDashoffset($porcent-html);
  }
}

@keyframes anim_circle-css {
  to {
    stroke-dashoffset: strokeDashoffset($porcent-css);
  }
}

@keyframes anim_circle-scss {
  to {
    stroke-dashoffset: strokeDashoffset($porcent-scss);
  }
}

@keyframes anim_circle-js {
  to {
    stroke-dashoffset: strokeDashoffset($porcent-js);
  }
}

@keyframes anim_circle-ts {
  to {
    stroke-dashoffset: strokeDashoffset($porcent-ts);
  }
}

@keyframes anim_circle-node {
  to {
    stroke-dashoffset: strokeDashoffset($porcent-nodejs);
  }
}

@keyframes anim_circle-react {
  to {
    stroke-dashoffset: strokeDashoffset($porcent-react);
  }
}

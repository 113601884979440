* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--Poppins);
}

.mobileNavbar-main {
  display: flex;
  align-items: center;
  justify-content: center;
  .app__MobileNavbar {
    position: absolute;
    display: flex;
    bottom: 0;
    justify-content: center;
    align-items: center;
    width: 100%;

    margin-bottom: 40px;
    z-index: 4000000;
    .navigation {
      position: fixed;
      width: 400px;
      height: 70px;
      background-color: var(--color-secondary);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;

      ul {
        display: flex;
        width: 350px;
        li {
          position: relative;
          list-style: none;
          width: 70px;
          height: 70px;
          z-index: 1;
          a {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            text-align: center;
            font-weight: 400;
            .icon {
              position: relative;
              display: block;
              line-height: 75px;
              font-size: 1.5em;
              text-align: center;
              margin-left: 10px;
              transition: 0.5s;
              color: var(--color-light-blue);
            }
            .text {
              position: absolute;
              color: var(--color-light-blue);
              font-weight: 400;
              font-size: 0.75em;
              letter-spacing: 0.05em;
              transition: 0.5s;
              opacity: 0;
              transform: translateY(20px);
            }
          }

          &:hover {
            a {
              .text {
                opacity: 1;
                transform: translateY(10px);
              }
            }
          }

          &:nth-child(1).active ~ .indicator {
            transform: translateX(calc(60px * 0));
          }

          &:nth-child(2).active ~ .indicator {
            transform: translateX(calc(60px * 0.91));
          }

          &:nth-child(3).active ~ .indicator {
            transform: translateX(calc(59px * 1.95));
          }

          &:nth-child(4).active ~ .indicator {
            transform: translateX(calc(58px * 3));
          }

          &:nth-child(5).active ~ .indicator {
            transform: translateX(calc(58px * 4));
          }
          &:nth-child(6).active ~ .indicator {
            transform: translateX(calc(58px * 5));
          }
        }

        .active {
          a {
            .icon {
              transform: translateY(-35px);
              color: var(--color-primary-black);
            }
            .text {
              opacity: 1;
              transform: translateY(10px);
            }
          }
        }
        .indicator {
          position: absolute;
          top: -50%;
          width: 70px;
          height: 70px;
          background-color: var(--color-primary-yellow);
          border-radius: 50%;
          border: 6px solid var(--color-primary-black);
          box-shadow: 0px -1px 3px rgba(206, 159, 23, 0.5);

          transition: 0.5s;

          // &::before {
          //   content: "";
          //   position: absolute;
          //   top: 50%;
          //   left: -22px;
          //   width: 20px;
          //   height: 20px;
          //   background: transparent;
          //   border-top-right-radius: 25px;
          //   box-shadow: 0px -10px 0 0 var(--color-primary-black);
          // }

          // &::after {
          //   content: "";
          //   position: absolute;
          //   top: 50%;
          //   right: -22px;
          //   width: 20px;
          //   height: 20px;
          //   background: transparent;
          //   border-top-left-radius: 20px;
          //   box-shadow: 0px -10px 0 0 var(--color-primary-black);
          // }
        }
      }
    }

    @media screen and(min-width:900px) {
      display: none;
    }
  }
}

// progress bar and main content styling
.app__pro-container {
  width: 100%;
  background-color: var(--color-secondary);
  padding: 1rem;
  margin: 1rem 0;
  display: flex;

  flex-direction: column;
  flex-wrap: wrap;
  .title {
    span {
      color: #8b8b90;
      text-transform: uppercase;
    }
  }
  .app__pro-item {
    position: relative;
    display: flex;
    align-items: center;
    .app__pro-content {
      img {
        width: 1rem;
        margin-right: 5px;
      }
    }
  }

  .app__pro-bar {
    position: relative;
    flex: 1;
    text-align: left;
  }
}

//prograss bar styleing...
.progress-bar-wrapper {
  margin: 1rem;

  .progress-bar {
    min-height: 0.1rem;
    width: 100%;

    background-color: #6e6447;

    .progress-bar-progress {
      min-height: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      //   box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
      min-width: 0;
      width: 100%;
      transition: all 1s ease;
    }

    .percentage {
      padding: 0;
      font-size: 0.1rem;
    }
  }
}

.app__header {
  margin: 0;
  .app__header-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @media screen and(max-width:900px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;
    }
    .app__header-info-container {
      height: 100%;
      flex: 0.65;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: flex-start;

      @media screen and(min-width:2000px) {
        width: 100%;
        margin-right: 0rem;
      }
      @media screen and(max-width:900px) {
        margin-top: 3rem;
      }

      .app__header-info {
        .app__header-badge {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          flex-direction: column;
          flex-wrap: wrap;

          .badge-cmp,
          .tag-cmp {
            padding: 1rem 2rem;
            border: var(--color-secondary);
            border-radius: 15px;
            flex-direction: row;
            width: auto;
            box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.1);
          }
          .tag-cmp {
            flex-direction: column;
            margin-top: 1rem;
            p {
              text-transform: uppercase;
              width: 100%;
              text-align: right;
              color: var(--color-primary-yellow);
            }
          }
          span {
            font-size: 2.5rem;

            @media screen and(min-width:2000px) {
              font-size: 5rem;
            }
          }
          @media screen and (max-width: 900px) {
            align-items: center;
            .badge-cmp,
            .tag-cmp {
              padding: 0rem;
              margin: 0;
              border: none;
              flex-direction: column;

              background-color: transparent;
              box-shadow: none;
            }
          }
        }
      } //header-info-end!

      .circles-left {
        display: flex;
        .circle-cmp {
          margin-top: 2rem;
          img {
            width: 70px;
          }
        }
        @media screen and(max-width:900px) {
          display: none;
        }
      } //left-circles-end!
    }
    //img section
    .app__header-img {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      position: relative;
      z-index: 22;

      img {
        width: 500px;
        object-fit: contain;
        z-index: 12;
      }
      .overlay-circle {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
      }

      @media screen and (max-width: 900px) {
        margin: 0;

        img {
          width: 80%;
        }
        .overlay-circle {
          width: 80%;
        }
      }
    } //image section end!

    //logos and brands
    .app__header-circles {
      // margin-top: -10rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      .circles-right {
        .circle-cmp {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          margin: 1rem 0;

          background: var(--color-secondary);
          box-shadow: 0 5px 10px rgba($color: #ce9f17, $alpha: 0.1);
          @media screen and(max-width:900px) {
            position: relative;
            display: flex;

            flex-direction: row;
            margin-bottom: -3rem;
            z-index: 1000;
          }
          img {
            width: 60%;
          }

          &:nth-child(1) {
            width: 120px;
            height: 120px;
            @media screen and(max-width:900px) {
              width: 80px;
              height: 80px;
            }
          }
          &:nth-child(3) {
            width: 120px;
            height: 120px;
            @media screen and(max-width:900px) {
              width: 80px;
              height: 80px;
            }
          }
        }
      }

      @media screen and (max-width: 900px) {
        width: 100%;
        // display: none;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: #8f6f10;
        div {
          margin: 0;
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }
    }
  } //container-end!
}

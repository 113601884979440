// skills and expreince styling

.app__Skills {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.app__skills-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  // min-height: 100%;
  .app__skills-list {
    .skills-title {
      text-align: center;
      text-transform: uppercase;
    }
    flex: 0.5;
    margin-right: 1rem;

    @media screen and(max-width:900px) {
      margin: 0;
      width: 100%;
    }
  }
  .app__skills-exp-container {
    flex: 1;
    @media screen and(max-width:900px) {
      order: -1;
      margin: 0;
      width: 100%;
    }

    .exp-title {
      text-align: center;
      text-transform: uppercase;
    }

    .app__skills-exp {
      background-color: var(--color-secondary);
      padding: 1rem;
      margin: 1rem 0;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .app__skills-exp-content {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        .title-section {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;
          justify-content: space-between;
          .title {
            .p-text {
              color: var(--color-primary-yellow);
            }
          }

          .exp-year {
            p {
              color: var(--color-primary-yellow);
              background-color: var(--color-primary-black);
              text-align: center;
              padding: 0.3rem 2.5rem;
              border-radius: 2rem;
            }
            @media screen and(max-width:900px) {
              order: -1;
              padding: 0.1rem 2rem;
            }
          }

          @media screen and(max-width:900px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }

        .respons {
          padding-top: 1rem;
          padding-left: 3rem;
          ul {
            li {
              span {
                color: var(--color-primary-yellow);
                margin-right: 1rem;
              }
            }
          }
        }
      }
    }
  }
  @media screen and(max-width:900px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

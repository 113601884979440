.app__works {
  flex: 1;
  width: 100%;
  flex-direction: row;
}

.app__work-container {
  .app__work-filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    margin: 4rem 0 2rem;

    .app__work-filter-item {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      background-color: var(--color-secondary);
      font-weight: 800;
      cursor: pointer;
      transition: all 0.3s ease;
      margin: 0.5rem;

      &:hover {
        box-shadow: 0px 0px 5px rgba(206, 159, 23, 0.5);
        color: var(--color-primary-yellow);
      }

      @media screen and(min-width:2000px) {
        padding: 1rem 2rem;
        border-radius: 0.85rem;
      }
    }
    .item-active {
      box-shadow: 0px 0px 5px rgba(206, 159, 23, 0.5);
      color: var(--color-primary-yellow);
    }
  }

  .app__work-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .app__work-item {
      flex-direction: column;
      justify-content: flex-start;
      width: 300px;
      align-self: normal;
      margin: 2rem;
      padding: 1rem;
      border-radius: 00.5rem;
      background-color: var(--color-secondary);
      color: var(--color-primary-yellow);
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        box-shadow: 0px 0px 5px rgba(206, 159, 23, 0.5);
      }

      @media screen and(min-width:2000px) {
        width: 470px;
        padding: 1rem;
        border-radius: 0.75rem;
      }

      @media screen and(max-width:300px) {
        width: 100%;
        margin: 1rem;
      }
    }
  }

  .app__work-img {
    width: 100%;
    height: 230px;

    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;
      object-fit: cover;
    }

    @media screen and(min-width:2000px) {
      height: 350px;
    }
  }

  .app__work-hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(36, 36, 47, 0.5);

    border-radius: 00.5rem;
    opacity: 0;
    transition: all 0.3s ease;

    div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: var(--color-primary-black);
      color: var(--color-primary-yellow);

      margin: 1rem;
      font-family: var(--font-base);
      font-weight: 800;
      cursor: pointer;
      transition: all 0.3s ease;

      svg {
        width: 50%;
        height: 50%;
        color: var(--color-primary-yellow);
      }

      &:hover {
        box-shadow: 0px 0px 5px rgba(206, 159, 23, 0.5);
      }
    }
  }

  // card details started
  .app__work-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title-info {
      align-items: center;
      justify-content: center;
      display: flex;
      position: relative;

      .title {
        text-align: center;
        margin-top: 1rem;
      }
      .tag {
        position: absolute;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        font-size: 00.85rem;
        background-color: var(--color-secondary);

        top: -25px;
      }
    }

    .tech-info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      margin-top: auto;

      align-items: center;
      font-size: 0.8rem;
      color: var(--color-primary-yellow);
      text-align: center;
      span {
        margin-top: 0.8rem;
        color: var(--color-light-blue);
        font-size: 0.7rem;
        font-weight: 300;
        text-transform: uppercase;
      }
    }
  }
}
